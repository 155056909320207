import * as React from "react";
import { Component, Fragment } from "react";
import { SurveyCreatorComponent, SurveyCreator } from "survey-creator-react";
import * as Survey from 'survey-core';
import { localization } from 'survey-creator-core';
// Import CSS files for SurveyJS (survey-core) and Survey Creator
import "survey-core/defaultV2.min.css";
import "survey-creator-core/survey-creator-core.min.css";
import { UserProfile } from "./survey-questions/user-profile";
import { MicroOptIn } from './survey-questions/micro-opt-in';
import { Email } from './survey-questions/email';

Survey.surveyLocalization.supportedLocales = ['en', 'zh-cn'];
Survey.ComponentCollection.Instance.add(UserProfile);
Survey.ComponentCollection.Instance.add(MicroOptIn);
Survey.ComponentCollection.Instance.add(Email);

const hiddenPropertiesList = ['logo', 'logoWidth', 'logoHeight', 'logoFit'];
const translations = localization.getLocale("");
translations.pehelp.isProgramApplicationForm = `<div class='admin-option-description'><p>If enabled, users will be presented with a 2-step application process.</p> <ul><li><strong>Step 1:</strong> Review your profile information</li><li><strong>Step 2:</strong> Program specific questions</li></div>`;

/**
 *
 * @param Survey
 */
const addDevZoneProgramSettings = (Survey) =>{
  const category = "DevZone Program";

  Survey.Serializer.addProperty("survey", {
    name: "isProgramApplicationForm:boolean",
    displayName: "Program application form",
    category: category,
    default: false,
    isSerializable: true
  });

  Survey.Serializer.addProperty("survey", {
    name: "showEmailTooltip:boolean",
    displayName: "Show email tooltip",
    category: category,
    default: false,
    isSerializable: true
  });

  Survey.Serializer.addProperty('survey', {
    name: 'customTermsText',
    type: 'text',
    isLocalizable: true,
    category: category,
    displayName: 'Custom terms text'
  });
}

class SurveyCreatorWidget extends Component {
  constructor() {
    super();

    // Add properties before SurveyJS Creator is constructed
    addDevZoneProgramSettings(Survey);

    // Instantiate Survey Creator
    this.creator = new SurveyCreator({
      showLogicTab: true,
      showTranslationTab: true,
      questionTypes: [
        'text',
        'checkbox',
        'radiogroup',
        'dropdown',
        'comment',
        'ranking',
        'boolean',
        'html',
        'matrix',
        'panel',
        'email',
      ]
    });
    // Enable auto save
    this.creator.isAutoSave = true;
    // Show notifications before and after a survey definition JSON is saved
    this.creator.showState = true;
    // Save the survey definition JSON to your web service
    this.creator.saveSurveyFunc = (saveNo, callback) => {
      $('#form_data').val(this.creator.text);
      // Call a function on your web service to store the survey definition JSON
      // As an alternative to this.creator.JSON, you can use the this.creator.text string property
      // saveSurveyJSON(this.id, this.creator.JSON, () => {
      //   callback(saveNo, true);
      // });
    };

    this.creator.onQuestionAdded.add(function (sender, options) {
      if (options.question.getType()) {
        let idx = 0;
        options.page.elements.forEach(el => {
          if (el.getType() === 'email') {
            idx += 1;
          }
        });
        options.question.name = options.question.getType();
        if (idx > 1) {
          options.question.name = `${options.question.getType()}${idx - 1}`;
        }
      }
    });

    this.creator.toolbox.changeCategories([{
        name: 'text',
        category: 'Default'
      }, {
        name: 'checkbox',
        category: 'Default'
      }, {
        name: 'radiogroup',
        category: 'Default'
      }, {
        name: 'dropdown',
        category: 'Default'
      }, {
        name: 'comment',
        category: 'Default'
      }, {
        name: 'ranking',
        category: 'Default'
      }, {
        name: 'boolean',
        category: 'Default'
      }, {
        name: 'html',
        category: 'Default'
      }, {
        name: 'matrix',
        category: 'Default'
      }, {
        name: 'panel',
        category: 'Default'
      }, {
        name: 'email',
        category: 'Custom'
      }, {
        name: 'user-profile',
        category: 'Custom'
      }, {
        name: 'micro-opt-in',
        category: 'Custom'
      }]);

    this.creator.toolbox.keepAllCategoriesExpanded = true;

    const licenseKey = document.querySelector('meta[name=surveyjs-license-key]').content;
    Survey.setLicenseKey(licenseKey);

    Survey.Serializer.findProperty('survey', 'logo').visible = false;

    this.creator.onShowingProperty.add(function (sender, options) {
      if (hiddenPropertiesList.includes(options.property.name)) {
        options.canShow = false;
      }
    });
  }
  componentDidMount() {
    // Load a survey definition JSON from you web service
    // ...
    // Assign the survey definition to Survey Creator
    // this.creator.JSON = yourJSON;
    let data = $('#form_data').val();

    if (data.length) {
      this.creator.JSON = JSON.parse(data);
    }
  }
  render() {
    return (
      <Fragment>
        <SurveyCreatorComponent creator={this.creator} />
      </Fragment>
    );
  }
}

export default SurveyCreatorWidget;
