import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import { Recommendation } from './recommender/Recommendation';
import { SettingsForm, SettingsIcon } from './recommender/SettingsForm';
import { HeaderNavigation, SideNavigation } from './recommender/Navigation';

export const componentLayouts = {
  threeColumns: '3-cols',
  fourColumns: '4-cols',
};

const HeadingAlignment = {
  left: 'left',
  center: 'center',
};

const NavigationPlacement = {
  topRight: 'topRight',
  side: 'side',
}

/**
 * Recommender component.
 *
 * @param {Object} props
 * @param {title} props.title
 * @param {Array} props.recommendations
 * @param {String} props.layout
 * @param {Boolean} props.overflow
 * @param {String} props.headingAlignment
 * @param {Boolean} props.showGreenElement
 *
 * @returns {JSX.ReactElement}
 *
 * @constructor
 */
const Recommender = (props) => {
  const {
    title,
    recommendations = [],
    layout,
    theme = 'light',
    overflow = true,
    headingAlignment = HeadingAlignment.left,
    showGreenElement = false,
    ctaText = '',
    navigationPlacement = NavigationPlacement.topRight,
    showSettings = false,
    interestAreas = [],
    onSettingsSave = function () {
    },
    onSettingsClick = async function () {
      return selectedInterestAreas;
    }
  } = props;

  let {
    noResultsText,
    selectedInterestAreas = [],
  } = props;

  if (!noResultsText) {
    noResultsText = 'No results found.'
  }

  const [items, setItems] = useState([]);
  const [selectedSettings, setSelectedSettings] = useState(selectedInterestAreas);
  const [showNavigation, setShowNavigation] = useState(true);
  const [settingsFormVisible, setSettingsFormVisible] = useState(false);
  const componentRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const offset = layout === componentLayouts.fourColumns ? 4 : 3;
  let currentIndex = 0;

  const css = {
    root: classnames({
      'nv-recommender': true,
      'nv-recommender--light': theme === 'light',
      'nv-recommender--dark': theme === 'dark',
      'nv-recommender--with-green-line': showGreenElement,
      'nv-recommender--no-results': items.length === 0,
      'nv-recommender--side-navigation': navigationPlacement === NavigationPlacement.side && overflow === false
    }),
    heading: classnames({
      'h--medium': true,
      'nv-recommender__heading': true,
      'text-center': headingAlignment === HeadingAlignment.center,
      'mb-0': true,
      'mt-0': true,
    }),
    container: classnames({
      'container-fluid': overflow === true,
      'container': overflow === false,
      'p-0': true,
    }),
    navigation: classnames({
      'col-lg-2': true,
      'd-flex': true,
      'justify-content-end': true,
      'nv-recommender-navigation': true,
    }),
  };

  const calcItemWidth = el => el.querySelector('.js-col').getBoundingClientRect().width;

  function calcOffset(rootElement, initialOffset) {
    let headingWidth = rootElement.querySelector('.scroll-container-wrapper').getBoundingClientRect().width;
    headingWidth += 50; // Offset for dz-3 new theme.
    const itemWidth = calcItemWidth(rootElement);
    let tmpOffset = initialOffset;
    while (tmpOffset * itemWidth > headingWidth) {
      tmpOffset = tmpOffset - 1;
    }
    return tmpOffset;
  }

  const prevItemCallback = () => {
    const {current: scrollElementRef} = scrollContainerRef;
    const {current: componentElementRef} = componentRef;
    if (!scrollElementRef) {
      return;
    }

    currentIndex -= 1;
    const maxItems = scrollElementRef.querySelectorAll('.js-col').length - calcOffset(componentElementRef, offset);
    if (currentIndex < 0) {
      currentIndex = maxItems;
    }
    let scrollDelta = calcItemWidth(scrollElementRef) * currentIndex;
    scrollElementRef.scrollTo({left: scrollDelta, behavior: 'smooth'});
  };

  const nextItemCallback = () => {
    const {current: scrollElementRef} = scrollContainerRef;
    const {current: componentElementRef} = componentRef;
    if (!scrollElementRef || !componentElementRef) {
      return;
    }

    currentIndex += 1;
    const maxItems = scrollElementRef.querySelectorAll('.js-col').length - calcOffset(componentElementRef, offset);
    if (currentIndex > maxItems) {
      currentIndex = 0
    }
    let scrollDelta = calcItemWidth(scrollElementRef) * currentIndex;
    scrollElementRef.scrollTo({left: scrollDelta, behavior: 'smooth'});
  };

  const closeCallback = () => setSettingsFormVisible(!settingsFormVisible);

  useEffect(() => {
    setItems(recommendations);

    return () => {
      setItems([]);
    };
  }, []);

  useEffect(() => {
    setShowNavigation(items.length > offset);
  }, [items, offset]);

  return (
    <div className={css.root} ref={componentRef}>
      {showSettings &&
        settingsFormVisible &&
        <SettingsForm
          interestAreas={interestAreas}
          selectedInterestAreas={selectedSettings}
          onSaveCallback={onSettingsSave}
          toggleCallback={closeCallback}
        />}

      <div className={css.container}>
        {!!title && (
          <div className='container nv-recommender-heading-container position-relative'>
            <div className='row'>
              <div className='col-lg-12 d-flex'>
                <h2 className={css.heading}>{title ?? ''}</h2>
                {showSettings && <SettingsIcon
                  toggleCallback={closeCallback}
                  selectedSettings={selectedSettings}
                  setSelectedSettings={setSelectedSettings}
                  onSettingsClick={onSettingsClick}
                />}
              </div>
              <div className={css.navigation}>
                {showNavigation &&
                  <HeaderNavigation onPreviousButtonClicked={prevItemCallback} onNextButtonClicked={nextItemCallback}/>}
              </div>
            </div>
          </div>
        )}

        <div className='position-relative'>
          <div className='scroll-container-wrapper' ref={scrollContainerRef}>
            <div className='container'>
              <div className='scroll-container row flex-nowrap'>
                {!items.length && <div><p className='p--medium'>{noResultsText}</p></div>}
                {items.length > 0 && items.map((item, idx) => <Recommendation key={idx} {...item} ctaText={ctaText}
                                                                              layout={layout}/>)}
                <div
                  className='col-xl-3 col-lg-4 col-md-4 col-sm-12 d-none d-sm-none d-md-none d-lg-block empty-card'></div>
              </div>
            </div>
          </div>
          {showNavigation &&
            <SideNavigation onPreviousButtonClicked={prevItemCallback} onNextButtonClicked={nextItemCallback}/>}
        </div>
      </div>
    </div>
  );
};

export default Recommender;
