import * as React from 'react';
import {useEffect, useRef, useState, useMemo} from 'react';
import {FacetedSearchFilterOptions} from './FacetedSearchFilterOptions';

const filterDefaults = {
  enabled: false,
  fieldName: '',
  type: '',
  label: '',
  widget: '',
  widgetSettings: '',
  weight: 0,
}

const updateAvailableFields = (fields, setter) => {
  const sortableFields = fields.filter(item => item.hasOwnProperty('facet') && item.facet === true);
  setter(sortableFields);
};

const fieldToConfig = (field, settings) => {
  const fieldName = field.name;
  const config = Object.assign({}, filterDefaults)
  config.fieldName = fieldName;
  config.type = field.type;
  if (settings && settings[fieldName]) {
    for (const property of ['enabled', 'label', 'widget', 'weight', 'widgetSettings']) {
      if (settings[fieldName][property]) {
        config[property] = settings[fieldName][property];
      }
    }
  }
  return config;
};

/**
 * "Filters" widget.
 *
 * @param {object} props
 * @param {object} props.info
 * @returns {JSX.Element}
 * @constructor
 */
export const FacetedSearchFilters = ({info}) => {
  const dataStorageElement = useRef(document.getElementById('typesense_collection_filters'));
  const [defaultValues, setDefaultValues] = useState({});
  const [availableFields, setAvailableFields] = useState([]);
  const [selectedFields, setSelectedFields] = useState({});

  // Init filers.
  useEffect(() => {
    if (!dataStorageElement.current.innerHTML) {
      return;
    }
    setDefaultValues(JSON.parse(dataStorageElement.current.innerHTML));
  }, []);

  useEffect(() => updateAvailableFields(info.fields, setAvailableFields), [info]);

  const widgetHandler = (val) => {
    setSelectedFields(val);
    dataStorageElement.current.innerHTML = JSON.stringify(val);
  };

  const filtersList = useMemo(() => {
    if (!availableFields.length) {
      return <tr><td colSpan="6">Unable to fetch collection details.</td></tr>
    }
    return availableFields.map(f => fieldToConfig(f, defaultValues)).map((f) => {
      return <FacetedSearchFilterOptions key={f.fieldName} field={f} onChangeCallback={widgetHandler} selectedFields={selectedFields}/>
    });
  }, [availableFields]);

  return <div className="fs-admin-widget fs-admin-filters-mapping">
    <div className="columns">
      <div className="column">
        <h3 className="fs-admin-panel-title">Filters</h3>
      </div>
      <div className="column">
        <table className="fs-admin-table">
          <thead>
          <tr>
            <th className="col-enabled">Enabled</th>
            <th>Name</th>
            <th>Type</th>
            <th>Label</th>
            <th>Widget</th>
            <th className="col-weight">Weight</th>
          </tr>
          </thead>
          <tbody>
          {filtersList}
          </tbody>
        </table>
        <p>Items are ordered by increasing weight.</p>
        <hr/>
      </div>
    </div>
  </div>;
};
